// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  'invoice-ar.invoice-landing-loading-message':
    'Pronto aparecerán las opciones de pago de su factura.',
  'invoice-ar.invoice-landing-loading-title': 'Cargando...',
  'invoice-ar.invoice-not-found-error': 'Factura no encontrada',
  'invoice-ar.invoice-paid-with-invoice-number': 'Factura {invoiceNumber} pagada',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable': 'Esta factura no puede ser pagada',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-description':
    'Póngase en contacto con su proveedor para conocer la situación de esta factura.',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-with-invoice-number':
    'La factura {invoiceNumber} no puede ser pagada',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available':
    'No se admite el pago en línea',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available-description':
    'El pago online no está permitido para esta factura. Por favor, pague la factura mediante transferencia bancaria.',
  'invoice-ar.invoice-unexpected-error':
    'Se ha producido un error inesperado, inténtelo de nuevo más tarde o póngase en contacto con el servicio de asistencia.',
  'payment-links.payment-link-expired': 'Este enlace de pago ha caducado',
  'payment-links.payment-link-expired-description':
    'Póngase en contacto con {profileName} para solicitar un nuevo enlace',
  'payment-links.payment-link-landing-loading-message':
    'Pronto aparecerán las opciones de pago de su Enlace de pago.',
  'payment-links.payment-link-landing-loading-title': 'Cargando...',
  'payment-links.payment-link-not-found': 'Enlace de pago no encontrado',
  'payment-links.payment-link-paid': 'Este enlace de pago ha sido pagado',
  'payment-links.payment-link-payment-error-no-suitable-payment-methods':
    'No se han encontrado métodos de pago adecuados para este enlace de pago. Ponte en contacto con el proveedor de tu enlace para resolver este problema.',
  'payment-links.payment-link-payment-loading-message': 'En breve aparecerá el estado de su pago.',
  'payment-links.payment-link-payment-paid-title': 'Su pago de {amount} a {profileName} tuvo éxito',
  'payment-links.payment-link-payment-pending-description':
    'Estamos esperando por el estado de su pago. Te informaremos una vez que el estado del pago este resuelto.',
  'payment-links.payment-link-payment-pending-title':
    'Su pago de {amount} a {profileName} está pendiente',
  'payment-links.payment-link-unexpected-error':
    'Se ha producido un error inesperado, inténtelo de nuevo más tarde o póngase en contacto con el servicio de asistencia.',
  'payment-links.payment-link-variable-amount-form-amount-label': 'Importe',
  'payment-links.payment-link-variable-amount-form-currency-label': 'Moneda',
  'payment-links.payment-link-variable-amount-form-description-helper-text':
    'Una nota para el pago que se está enviando',
  'payment-links.payment-link-variable-amount-form-description-label': 'Descripción',
  'payment-links.payment-link-variable-amount-form-error-amount-positive':
    'El importe debe ser positivo',
  'payment-links.payment-link-variable-amount-form-error-payment-amount-too-high':
    'El importe es demasiado alto',
  'payment-links.payment-link-variable-amount-form-error-payment-amount-too-low':
    'El importe es demasiado bajo',
  'payment-links.payment-link-variable-amount-form-pay-now-button': 'Pagar ahora',
};
