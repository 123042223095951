export function isValidUUID(uuid: string) {
  const uuidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidPattern.test(uuid);
}

export type SystemColorScheme = "light" | "dark";

export function getPreferredColorScheme(): SystemColorScheme {
  const schemeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  return schemeMediaQuery.matches ? "dark" : "light";
}
