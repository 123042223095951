// Auto generated file. Do no change. Go to Crowdin to update the translations and run './node_modules/.bin/mollie-crowdin download' to update this file.
export default {
  'invoice-ar.invoice-landing-loading-message':
    'De betalingsopties voor je factuur verschijnen binnenkort.',
  'invoice-ar.invoice-landing-loading-title': 'Laden...',
  'invoice-ar.invoice-not-found-error': 'Factuur niet gevonden',
  'invoice-ar.invoice-paid-with-invoice-number': 'Factuur {invoiceNumber} is betaald',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable': 'Factuur is niet betaalbaar',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-description':
    'Neem contact op met je leverancier voor meer informatie over de status van deze factuur.',
  'invoice-ar.invoice-payment-error-invoice-is-not-payable-with-invoice-number':
    'Factuur {invoiceNumber} is niet betaalbaar',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available':
    'Online betaling niet ondersteund',
  'invoice-ar.invoice-payment-error-no-supported-payment-methods-available-description':
    'Online betalen wordt niet ondersteund voor deze factuur. Betaal de factuur via bankoverschrijving.',
  'invoice-ar.invoice-unexpected-error':
    'Er is een onverwachte fout opgetreden. Probeer het later opnieuw of neem contact op met de klantenservice.',
  'payment-links.payment-link-expired': 'Deze betaallink is verlopen',
  'payment-links.payment-link-expired-description':
    'Neem contact op met {profileName} om een nieuwe link aan te vragen',
  'payment-links.payment-link-landing-loading-message':
    'De betalingsopties van je Payment Link verschijnen binnenkort.',
  'payment-links.payment-link-landing-loading-title': 'Laden...',
  'payment-links.payment-link-not-found': 'Betaallink niet gevonden',
  'payment-links.payment-link-paid': 'Deze betaallink is betaald',
  'payment-links.payment-link-payment-error-no-suitable-payment-methods':
    'Geen geschikte betaalmethoden gevonden voor deze betaallink. Neem contact op met de leverancier van de link om dit probleem op te lossen.',
  'payment-links.payment-link-payment-loading-message': 'Je betalingsstatus verschijnt binnenkort.',
  'payment-links.payment-link-payment-paid-title':
    'Je betaling van {amount} aan {profileName} is gelukt',
  'payment-links.payment-link-payment-pending-description':
    'We wachten op de status van je betaling. We laten het je weten zodra de betaling is verwerkt.',
  'payment-links.payment-link-payment-pending-title':
    'Je betaling van {amount} aan {profileName} is in behandeling',
  'payment-links.payment-link-unexpected-error':
    'Er is een onverwachte fout opgetreden. Probeer het later opnieuw of neem contact op met de klantenservice.',
  'payment-links.payment-link-variable-amount-form-amount-label': 'Bedrag',
  'payment-links.payment-link-variable-amount-form-currency-label': 'Valuta',
  'payment-links.payment-link-variable-amount-form-description-helper-text':
    'Een opmerking voor de betaling die je verstuurt',
  'payment-links.payment-link-variable-amount-form-description-label': 'Beschrijving',
  'payment-links.payment-link-variable-amount-form-error-amount-positive':
    'Bedrag moet positief zijn',
  'payment-links.payment-link-variable-amount-form-error-payment-amount-too-high':
    'Bedrag is te hoog',
  'payment-links.payment-link-variable-amount-form-error-payment-amount-too-low':
    'Bedrag is te laag',
  'payment-links.payment-link-variable-amount-form-pay-now-button': 'Nu betalen',
};
