import { FC } from "react";
import { messages } from "app/i18n";
import { Flex } from "@mollie/ui-react";
import { StateWithFooter } from "@mollie/revenue-collection-components";

export const UnexpectedRootErrorState: FC = () => {
  return (
    <Flex direction="column" height="100%" marginInline="auto" maxWidth="400px">
      <StateWithFooter
        title={messages.paymentLinkUnexpectedError.defaultMessage}
        variant="error"
      />
    </Flex>
  );
};
