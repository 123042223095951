import { FC, useCallback, useMemo, useState } from "react";
import { Navigate, Params, useLocation, useParams } from "react-router-dom";
import {
  AutoPayData,
  ErrorData,
  PaymentLinkPayData,
  PaymentLinkPayRequestData,
  PublicPaymentLinkData,
  SimpleErrorData,
} from "app/types";
import { messages } from "app/i18n";
import { FormattedMessage, useIntl } from "react-intl";
import { UnexpectedErrorState, VariableAmountFormFields } from "app/components";
import { paymentLinksApiUrl } from "app/constants";
import { Avatar, Button, Flex, Stack, Text } from "@mollie/ui-react";
import { Formik, FormikHelpers } from "formik";
import {
  ExpectedErrorState,
  Footer,
} from "@mollie/revenue-collection-components";

export type VariableAmountFormValues = {
  amount: number | string;
  currency: string;
  description: string;
};

enum Error {
  BadResponse = "Bad response from server",
  PaymentAmountTooHigh = "Payment amount is too high",
  PaymentAmountTooLow = "Payment amount is too low",
  NoSuitablePaymentMethods = "No suitable payment methods found for the given payment link",
}

export const PaymentLinkVariablePaymentPage: FC = () => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);
  const params: Params<string> = useParams();
  const {
    state,
  }: {
    state: {
      paymentLinkData: PublicPaymentLinkData;
      autoPayData: AutoPayData | null;
    };
  } = useLocation();
  const initialValues: VariableAmountFormValues =
    useMemo<VariableAmountFormValues>(
      () => ({
        amount: "",
        currency: "EUR",
        description: "",
      }),
      [],
    );

  const onSubmit = useCallback(
    (
      values: VariableAmountFormValues,
      formikHelpers: FormikHelpers<VariableAmountFormValues>,
    ) => {
      const apiUrl = new URL(
        `api/bff/v1/payment-links/${params.paymentLinkHash}/pay`,
        paymentLinksApiUrl,
      );
      const body: PaymentLinkPayRequestData = {
        amount: {
          value: String(values.amount),
          currency: values.currency,
        },
      };
      if (values.description) {
        body.customerMessage = values.description;
      }
      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            formikHelpers.setSubmitting(false);

            if (response.status === 422) {
              return response
                .json()
                .then((data: ErrorData | SimpleErrorData) => {
                  if ((data as SimpleErrorData).error) {
                    const error = (data as SimpleErrorData).error as Error;
                    if (Object.values(Error).includes(error)) {
                      return setError(error);
                    }
                  }
                  if (
                    (data as ErrorData).detail &&
                    Object.values(Error).includes(
                      (data as ErrorData).detail as Error,
                    )
                  ) {
                    const errorKey = Object.keys(Error).find(
                      (key) =>
                        Error[key as keyof typeof Error] ===
                        (data as ErrorData).detail,
                    );

                    formikHelpers.setFieldError(
                      "amount",
                      intl.formatMessage(
                        messages[
                          `paymentLinkVariableAmountFormError${errorKey}` as keyof typeof messages
                        ],
                      ),
                    );
                  } else {
                    setError(Error.BadResponse);
                  }
                });
            }

            return setError(Error.BadResponse);
          } else {
            response.json().then((data: PaymentLinkPayData) => {
              // deepcode ignore OR: this URL is supplied by the backend
              window.location.replace(data.redirectUrl);
            });
          }
        })
        .catch(() => {
          return setError(Error.BadResponse);
        });
    },
    [intl, params.paymentLinkHash],
  );

  if (error === Error.BadResponse) {
    return <UnexpectedErrorState />;
  }
  if (error === Error.NoSuitablePaymentMethods) {
    return (
      <ExpectedErrorState
        error={intl.formatMessage(
          messages.paymentLinkPaymentErrorNoSuitablePaymentMethods,
        )}
      />
    );
  }

  if (!state?.paymentLinkData) {
    return (
      <Navigate
        to={`/${params.lang}/payment/${params.paymentLinkHash}`}
        replace={true}
      />
    );
  }

  return (
    <Stack margin="auto" spacing="space-300" width="100%">
      <Flex gap="space-100" padding="space-100" direction="column">
        {state.paymentLinkData.profileLogoUrl && (
          <Avatar
            variant="organisation"
            size="extra-large"
            src={state.paymentLinkData.profileLogoUrl}
            marginInline="auto"
            marginBlockEnd="space-50"
          />
        )}
        <Text variant="subtitle-medium" textAlign="center">
          {state.paymentLinkData.profileName}
        </Text>
        <Text variant="body-medium" textAlign="center" color="secondary">
          {state.paymentLinkData.description}
        </Text>
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={false}
        validateOnMount={false}
        isInitialValid={false}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <>
            <VariableAmountFormFields
              availableCurrencies={state.paymentLinkData.availableCurrencies}
              autoPayData={state.autoPayData}
            />
            <Stack spacing="space-100">
              <Button
                variant="primary"
                width="100%"
                loading={isSubmitting}
                disabled={!isValid}
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <FormattedMessage
                  {...messages.paymentLinkVariableAmountFormPayNowButton}
                />
              </Button>
              <Footer />
            </Stack>
          </>
        )}
      </Formik>
    </Stack>
  );
};
