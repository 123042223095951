import { FC } from "react";
import { messages } from "app/i18n";
import { useIntl } from "react-intl";
import { StateWithFooter } from "@mollie/revenue-collection-components";

export const NotFoundState: FC = () => {
  const intl = useIntl();
  return (
    <StateWithFooter
      title={intl.formatMessage(messages.paymentLinkNotFound)}
      variant="empty"
    />
  );
};
