import { PageState, PageStateProps } from "@mollie/ui-react";
import { FC } from "react";
import { Footer } from "../Footer";

type StateWithFooterProps = {} & Pick<
  PageStateProps,
  "title" | "description" | "variant" | "icon"
>;

export const StateWithFooter: FC<StateWithFooterProps> = ({
  title,
  description,
  variant,
  icon,
}) => {
  return (
    <PageState
      title={title}
      description={description}
      variant={variant}
      icon={icon}
    >
      <Footer />
    </PageState>
  );
};
