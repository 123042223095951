import { FC } from "react";
import { StateWithFooter } from "@mollie/revenue-collection-components";

type ExpectedErrorStateProps = {
  error: string;
  description?: string;
};

export const ExpectedErrorState: FC<ExpectedErrorStateProps> = ({
  error,
  description,
}) => {
  return (
    <StateWithFooter title={error} variant="error" description={description} />
  );
};
