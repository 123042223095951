import { FC, useCallback, useEffect } from "react";
import { ComboBox, Flex, TextField } from "@mollie/ui-react";
import { Field, FieldProps, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { messages } from "app/i18n";
import { VariableAmountFormValues } from "app/pages";
import { AutoPayData, Currency } from "app/types";
import { extractCurrencyAndAmount } from "app/helpers";

type VariableAmountFormFieldsProps = {
  availableCurrencies: Currency[];
  autoPayData: AutoPayData | null;
};

export const VariableAmountFormFields: FC<VariableAmountFormFieldsProps> = ({
  availableCurrencies,
  autoPayData,
}) => {
  const intl = useIntl();
  const { errors, setFieldValue, submitForm, values } =
    useFormikContext<VariableAmountFormValues>();
  const isMultipleCurrency = availableCurrencies.length > 1;

  const getAmountStep = useCallback(
    (currencyCode: string) => {
      const currency = availableCurrencies.find(
        (currency) => currency.code === currencyCode,
      );

      return 1 / 10 ** (currency?.precision ?? 2);
    },
    [availableCurrencies],
  );

  useEffect(() => {
    const handleAutoPay = async () => {
      if (autoPayData?.amount) {
        const { currency, amount } = extractCurrencyAndAmount(
          autoPayData.amount,
        );
        await setFieldValue("currency", currency, false);
        await setFieldValue("amount", amount, false);
        if (autoPayData.message) {
          await setFieldValue("description", autoPayData.message, false);
        }

        if (amount != "") {
          // Timeout is needed to ensure the form is re-rendered before submit
          setTimeout(submitForm, 50);
        }
      }
    };

    handleAutoPay();
  }, [autoPayData, setFieldValue, submitForm]);

  return (
    <>
      <Flex alignItems="flex-start">
        {isMultipleCurrency && (
          <Field name="currency">
            {({ field }: FieldProps) => (
              <ComboBox
                {...field}
                label={intl.formatMessage(
                  messages.paymentLinkVariableAmountFormCurrencyLabel,
                )}
                width="25%"
              >
                {availableCurrencies.map((currency) => (
                  <ComboBox.Item
                    key={currency.code}
                    value={currency.code}
                    onClick={(value) => {
                      setFieldValue("currency", value);
                    }}
                  >
                    {currency.code}
                  </ComboBox.Item>
                ))}
              </ComboBox>
            )}
          </Field>
        )}
        <Field
          name="amount"
          validate={(value: number) =>
            value <= 0
              ? intl.formatMessage(
                  messages.paymentLinkVariableAmountFormErrorAmountPositive,
                )
              : null
          }
        >
          {({ field }: FieldProps) => (
            <TextField
              {...field}
              width={isMultipleCurrency ? "75%" : "100%"}
              type="number"
              label={intl.formatMessage(
                messages.paymentLinkVariableAmountFormAmountLabel,
              )}
              min={0}
              step={getAmountStep(values.currency)}
              prefix={isMultipleCurrency ? "" : availableCurrencies[0].code}
              validationText={errors.amount}
              placeholder={intl.formatNumber(getAmountStep(values.currency))}
              autoFocus={true}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  submitForm();
                }
              }}
            />
          )}
        </Field>
      </Flex>
      <Field name="description">
        {({ field }: FieldProps) => (
          <TextField
            {...field}
            label={intl.formatMessage(
              messages.paymentLinkVariableAmountFormDescriptionLabel,
            )}
            helperText={intl.formatMessage(
              messages.paymentLinkVariableAmountFormDescriptionHelperText,
            )}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                submitForm();
              }
            }}
          />
        )}
      </Field>
    </>
  );
};
