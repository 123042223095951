import * as ReactDOM from "react-dom/client";
import "./styles.css";
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Params,
  redirect,
  RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import "@mollie/ui-assets/css/index.css";
import {
  defaultAppLanguage,
  isValidLanguage,
} from "@mollie/revenue-collection-intl";
import { UnexpectedRootErrorState } from "./components";
import {
  PaymentLinkLandingPage,
  PaymentLinkPaymentPage,
  PaymentLinkVariablePaymentPage,
  PaymentLinkStatusPage,
} from "./pages";
import { marketingUrl } from "app/constants";
import { AppWrapper } from "@mollie/revenue-collection-components";

const environment: Environment = import.meta.env.MODE as Environment;

if (environment === "stag" || environment === "prod") {
  Sentry.init({
    dsn: "https://305718d204d130744484cf9ee925375c@o29109.ingest.sentry.io/4507804420538368",
    environment: environment,
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    loader: (): null => {
      window.location.replace(marketingUrl);

      return null;
    },
  },
  {
    path: "/:lang/*",
    element: <AppWrapper />,
    errorElement: <UnexpectedRootErrorState />,
    children: [
      {
        path: "payment/:paymentLinkHash/qr?/:amount?/:message?",
        loader: ({ params }: LoaderFunctionArgs): Response | null => {
          return genericLoader(params);
        },
        element: <PaymentLinkLandingPage />,
      },
      {
        path: "payment/:paymentLinkHash/pay",
        loader: ({ params }: LoaderFunctionArgs): Response | null => {
          return genericLoader(params);
        },
        element: <PaymentLinkPaymentPage />,
      },
      {
        path: "payment/:paymentLinkHash/pay-variable",
        loader: ({ params }: LoaderFunctionArgs): Response | null => {
          return genericLoader(params);
        },
        element: <PaymentLinkVariablePaymentPage />,
      },
      {
        path: "status/:paymentLinkPaymentId",
        loader: ({ params }: LoaderFunctionArgs): Response | null => {
          return genericLoader(params);
        },
        element: <PaymentLinkStatusPage />,
      },
      {
        // This route is for backwards compatibility with the old payment link URL
        // The paymentLinkPaymentId is actually a hash
        path: "pay/status/:paymentLinkPaymentId",
        loader: ({ params }: LoaderFunctionArgs): Response | null => {
          return genericLoader(params);
        },
        element: <PaymentLinkStatusPage />,
      },
      {
        path: "*",
        loader: ({ params }: LoaderFunctionArgs): null | Response => {
          const { lang } = params as { lang: string };

          if (!isValidLanguage(lang)) {
            return redirect(
              `/${defaultAppLanguage()}/${params.lang}/${params["*"]}`,
            );
          }

          window.location.replace(marketingUrl);

          return null;
        },
      },
    ],
  },
]);

function genericLoader(params: Params<string>) {
  const { lang } = params as { lang: string };

  if (!isValidLanguage(lang)) {
    return redirect(`/${defaultAppLanguage()}/${params["*"]}`);
  }

  if (
    params.paymentLinkHash === undefined &&
    params.paymentLinkPaymentId === undefined
  ) {
    return redirect("/");
  }

  return null;
}

const documentRoot = document.getElementById("root");
if (documentRoot !== null) {
  ReactDOM.createRoot(documentRoot).render(<RouterProvider router={router} />);
}
