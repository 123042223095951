import { FC } from "react";
import { messages } from "app/i18n";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Flex, Text } from "@mollie/ui-react";
import { PaymentMethodIcon } from "app/components";
import { PaymentLinkPaymentData } from "app/types";
import { Footer } from "@mollie/revenue-collection-components";

type PaymentPaidStateProps = {
  paymentLinkPaymentData: PaymentLinkPaymentData;
};

export const PaymentPaidState: FC<PaymentPaidStateProps> = ({
  paymentLinkPaymentData,
}) => {
  return (
    <Flex direction="column" margin="auto">
      <PaymentMethodIcon
        methodName={paymentLinkPaymentData.payment.method.name}
        schema={paymentLinkPaymentData.payment.method.schema}
        fallbackIcon={paymentLinkPaymentData.payment.method.icon}
      />
      <Text variant="heading-title-3" textAlign="center">
        <FormattedMessage
          {...messages.paymentLinkPaymentPaidTitle}
          values={{
            amount: (
              <FormattedNumber
                value={Number.parseFloat(
                  paymentLinkPaymentData.payment.amount.value,
                )}
                style="currency"
                currency={paymentLinkPaymentData.payment.amount.currency}
              />
            ),
            profileName: paymentLinkPaymentData.paymentLink.profileName,
          }}
        />
      </Text>
      <Footer />
    </Flex>
  );
};
