import {
  getIntlProviderProps,
  getLocaleFromAppLanguage,
} from "@mollie/revenue-collection-intl";
import { IntlProvider } from "react-intl";
import { Flex } from "@mollie/ui-react";
import { Outlet } from "react-router-dom";
import { useAppLanguage } from "@mollie/revenue-collection-intl";

export const AppWrapper = () => {
  const appLanguage = useAppLanguage();

  return (
    <Flex direction="column" height="100%" marginInline="auto" maxWidth="400px">
      <IntlProvider
        {...getIntlProviderProps(getLocaleFromAppLanguage(appLanguage))}
      >
        <Outlet />
      </IntlProvider>
    </Flex>
  );
};
